import React from 'react';
import '../App.css';
import HomeSection from '../components/HomeSection';


function Home() {
 
  return (
    <>
      <HomeSection />
    </>
  );
}

export default Home;