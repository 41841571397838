import React from 'react';
import Home from './pages/Home';
import Navbar from './components/Navbar';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1> Under Construction</h1>
        <Navbar/>
        <Home/>
      

      </header>
    </div>
  );
}

export default App;
