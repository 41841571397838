//Functional Code for the Navigation
//called in App.js

import React from 'react';
//import { Link,  useLocation } from 'react-router-dom';
//import Auth from './auth'
import './Navbar.css';
import '../App.css';

//called in App.js
//NavBar component recives the currentPage Prop to display Header Text
//state variables click and headerTitle defined using useState Hook
function Navbar() {

  return (
    <>
      <nav className='navbar'>
        
      
         
         
            <p className='nav-item'>  <a href="https://mash.jtwebdev.com.au" >React Mash API Project</a></p>
            <p className='nav-item'>  <a href="https://serendipity.jtwebdev.com.au" >Laravel Social Media Project</a></p>
            
          
    
      </nav>
    </>
  );
}

export default Navbar;
