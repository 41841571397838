
import React from 'react';
import '../App.css';
import './HomeSection.css';
//import Cards from './Cards';

function HomeSection() {
  return (
    <div className='home-container'>
      <video src='/videos/vid4.mp4' autoPlay loop muted />
      
    </div>
  );
}

export default HomeSection;